import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const SmartResult = (props) => {
  const {
    id,
    content,
    animationSpeed,
    resultContainerStyle,
    cursorStyle,
    placeholder,
    title,
    titleStyle,
  } = props;

  const [displayedTitle, setDisplayedTitle] = useState('');
  const [isTitleComplete, setIsTitleComplete] = useState(false);
  const [displayedContent, setDisplayedContent] = useState([]);
  const [isRendering, setIsRendering] = useState(false);

  const currentContentIndexRef = useRef(0);
  const groupStringIndexRef = useRef(0);

  const groupConsecutiveStrings = (contentArray) => {
    const groupedContent = [];
    let currentGroup = [];

    for (let item of contentArray) {
      if (typeof item === 'string') {
        currentGroup.push(item);
      } else {
        if (currentGroup.length > 1) {
          groupedContent.push([...currentGroup]);
        } else if (currentGroup.length === 1) {
          groupedContent.push(currentGroup[0]);
        }
        currentGroup = [];
        groupedContent.push(item);
      }
    }
    if (currentGroup.length > 1) {
      groupedContent.push([...currentGroup]);
    } else if (currentGroup.length === 1) {
      groupedContent.push(currentGroup[0]);
    }
    return groupedContent;
  };

  useEffect(() => {
    setDisplayedTitle('');
    setIsTitleComplete(false);
    setDisplayedContent([]);
    currentContentIndexRef.current = 0;
    groupStringIndexRef.current = 0;
    setIsRendering(false);

    if (!title) {
      setIsTitleComplete(true);
      return;
    }

    if (animationSpeed === 0) {
      setDisplayedTitle(title);
      setIsTitleComplete(true);
      return;
    }

    const titleWords = title.split(' ');
    let titleWordIndex = 0;

    const titleInterval = setInterval(() => {
      setDisplayedTitle((prevTitle) => prevTitle + (titleWordIndex > 0 ? ' ' : '') + titleWords[titleWordIndex]);
      titleWordIndex += 1;

      if (titleWordIndex === titleWords.length) {
        clearInterval(titleInterval);
        setIsTitleComplete(true);
      }
    }, animationSpeed);

    return () => clearInterval(titleInterval);
  }, [title, animationSpeed]);

  useEffect(() => {
    if (!isTitleComplete || !content) return;

    if (animationSpeed === 0) {
      setDisplayedContent(groupConsecutiveStrings(Array.isArray(content) ? content : [content]).map(item => (
        Array.isArray(item) ? { type: 'group', items: item } : typeof item === 'string' ? { type: 'string', text: item } : { type: 'element', element: item }
      )));
      setIsRendering(false);
      return;
    }

    const contentArray = Array.isArray(content) ? content : [content];
    const groupedContentArray = groupConsecutiveStrings(contentArray);

    let isCancelled = false;

    const renderNextContent = () => {
      if (isCancelled || currentContentIndexRef.current >= groupedContentArray.length) {
        setIsRendering(false);
        return;
      }

      setIsRendering(true);
      const currentItem = groupedContentArray[currentContentIndexRef.current];

      if (Array.isArray(currentItem)) {
        let groupStringIndex = 0;
        groupStringIndexRef.current = groupStringIndex;
        const groupStrings = currentItem;
        const groupRenderedStrings = [];

        setDisplayedContent((prev) => [
          ...prev,
          { type: 'group', items: [] },
        ]);

        const renderNextStringInGroup = () => {
          if (isCancelled || groupStringIndex >= groupStrings.length) {
            currentContentIndexRef.current += 1;
            setIsRendering(false);
            setTimeout(renderNextContent, 0);
            return;
          }

          groupStringIndexRef.current = groupStringIndex;
          let wordIndex = 0;
          const words = groupStrings[groupStringIndex].split(' ');

          const typingInterval = setInterval(() => {
            if (isCancelled) {
              clearInterval(typingInterval);
              return;
            }

            if (!groupRenderedStrings[groupStringIndex]) {
              groupRenderedStrings[groupStringIndex] = '';
            }

            groupRenderedStrings[groupStringIndex] += (wordIndex > 0 ? ' ' : '') + words[wordIndex];

            setDisplayedContent((prev) => {
              const newDisplayedContent = [...prev];
              const groupItem = newDisplayedContent[currentContentIndexRef.current];
              if (groupItem && groupItem.type === 'group') {
                groupItem.items = [...groupRenderedStrings];
              }
              return newDisplayedContent;
            });

            wordIndex += 1;

            if (wordIndex >= words.length) {
              clearInterval(typingInterval);
              groupStringIndex += 1;
              setTimeout(renderNextStringInGroup, 0);
            }
          }, animationSpeed);
        };

        renderNextStringInGroup();
      } else if (typeof currentItem === 'string') {
        let wordIndex = 0;
        const words = currentItem.split(' ');

        setDisplayedContent((prev) => [
          ...prev,
          { type: 'string', text: '' },
        ]);

        const typingInterval = setInterval(() => {
          if (isCancelled) {
            clearInterval(typingInterval);
            return;
          }

          setDisplayedContent((prev) => {
            const newDisplayedContent = [...prev];
            const updatedItem = (newDisplayedContent[currentContentIndexRef.current].text || '') + (wordIndex > 0 ? ' ' : '') + words[wordIndex];
            newDisplayedContent[currentContentIndexRef.current].text = updatedItem;
            return newDisplayedContent;
          });

          wordIndex += 1;

          if (wordIndex >= words.length) {
            clearInterval(typingInterval);
            currentContentIndexRef.current += 1;
            setIsRendering(false);
            setTimeout(renderNextContent, 0);
          }
        }, animationSpeed);
      } else if (React.isValidElement(currentItem)) {
        setDisplayedContent((prev) => [...prev, { type: 'element', element: currentItem }]);
        currentContentIndexRef.current += 1;
        setIsRendering(false);
        setTimeout(renderNextContent, 0);
      } else {
        currentContentIndexRef.current += 1;
        setIsRendering(false);
        setTimeout(renderNextContent, 0);
      }
    };

    renderNextContent();

    return () => {
      isCancelled = true;
    };
  }, [isTitleComplete, animationSpeed, content]);

  const blinkKeyframes = `
    @keyframes blink {
      0% { opacity: 0; }
      50% { opacity: 1; }
      100% { opacity: 0; }
    }
  `;

  const fadeInKeyframes = `
    @keyframes fadeIn {
      from { opacity: 0; transform: translateY(10px); }
      to { opacity: 1; transform: translateY(0); }
    }
  `;

  useEffect(() => {
    if (animationSpeed === 0) return;

    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = `${blinkKeyframes} ${fadeInKeyframes}`;
    document.head.appendChild(styleSheet);

    return () => document.head.removeChild(styleSheet);
  }, [animationSpeed]);

  const renderContent = (item) => {
    if (typeof item === 'string') {
      return item;
    } else if (React.isValidElement(item)) {
      return item;
    }
    return null;
  };

  return (
    <div id={id} style={{ position: 'relative', ...resultContainerStyle }}>
      {title && (
        <h2 className="smart-result-title" style={titleStyle}>
          {displayedTitle}
          {animationSpeed !== 0 && !isTitleComplete && (
            <span
              style={{
                ...cursorStyle,
                fontSize: '20px',
                marginLeft: '5px',
                animation: 'blink 1s infinite',
              }}
            >
              ●
            </span>
          )}
        </h2>
      )}
      {displayedContent.map((item, index) => {
        if (item.type === 'group') {
          return (
            <ul key={index} style={{ marginLeft: '20px' }}>
              {item.items.map((str, idx) => (
                <li key={idx} className='string-list-style' style={{ position: 'relative', fontSize: '18px', color: 'black',marginBottom: '0.5em' }}>
                  {str}
                  {animationSpeed !== 0 && isRendering &&
                    index === currentContentIndexRef.current &&
                    idx === groupStringIndexRef.current && (
                      <span
                        style={{
                          ...cursorStyle,
                          fontSize: '20px',
                          marginLeft: '5px',
                          marginBottom: '0.5em',
                          animation: 'blink 1s infinite',
                        }}
                      >
                        ●
                      </span>
                    )}
                </li>
              ))}
            </ul>
          );
        } else if (item.type === 'string') {
          return (
            <div
              key={index}
              className='string-style'
              style={{
                fontSize: '18px',
                color: 'black',
                marginBottom: '0.5em',
              }}
            >
              {item.text}
              {animationSpeed !== 0 && isRendering &&
                index === currentContentIndexRef.current && (
                  <span
                    style={{
                      ...cursorStyle,
                      fontSize: '20px',
                      marginLeft: '5px',
                      animation: 'blink 1s infinite',
                    }}
                  >
                    ●
                  </span>
                )}
            </div>
          );
        } else if (item.type === 'element') {
          return (
            <div
              key={index}
              className='elements-style'
              style={{
                fontSize: '18px',
                color: 'black',
                marginBottom: '1em',
                ...(animationSpeed !== 0 ? { animation: 'fadeIn 0.5s ease-in-out forwards', opacity: 0 } : {}),
              }}
            >
              {item.element}
            </div>
          );
        }
        return null;
      })}
      {!isRendering && displayedContent.length === 0 && placeholder && (
        <div style={{ fontSize: '18px', color: 'black' }}>{placeholder}</div>
      )}
    </div>
  );
};

SmartResult.defaultProps = {
  id: '',
  content: null,
  title: null,
  animationSpeed: 100,
  resultContainerStyle: {},
  cursorStyle: {},
  placeholder: '',
  titleStyle: {},
};

SmartResult.propTypes = {
  id: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
    PropTypes.string,
    PropTypes.element,
  ]),
  animationSpeed: PropTypes.number,
  resultContainerStyle: PropTypes.object,
  cursorStyle: PropTypes.object,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  titleStyle: PropTypes.object,
};

export default SmartResult;
